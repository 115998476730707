import React, { useState } from "react";
import style from "./style.module.css";
import axios from "axios";
import Logo from "../../../assets/logo.png";
import { RightArrow } from "../../../assets/logo/logos";
import ContactComponent from "../../../pages/admaster/admaster/contact/contact";
import { useNavigate } from "react-router";

export default function FooterCompoent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState("Email sent successfully!");
  const [emailFlag, setEmailFlag] = useState(false);

  const ValidateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const showEmailSentText = async () => {
    if (email.length > 0) {
      const bool = ValidateEmail();
      console.log(bool);
      if (bool) {
        await axios.post("https://api.estubank.com/api/v1/early-access", {
          email: email,
        });
        setEmailSent("Email sent successfully!");
        setEmailFlag(true);
        setTimeout(() => {
          setEmailFlag(false);
        }, 2000);
      } else {
        setEmailSent("You have entered an invalid email address!");
        setEmailFlag(true);
        setTimeout(() => {
          setEmailFlag(false);
        }, 2000);
      }
    } else {
      setEmailSent("Please Enter an  email address!");
      setEmailFlag(true);
      setTimeout(() => {
        setEmailFlag(false);
      }, 2000);
    }
  };

  return (
    <div className={style.container}>
      {/* <img src={Logo} alt="logo" /> */}
      {/* <div className={style.title}>Partner with estu</div> */}
      {/* <div className={style.emailContainer}>
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Share your Email address"
        />
        <div onClick={showEmailSentText} className={style.emailButton}>
          Submit
          //  <RightArrow /> 
        </div>
      </div> */}
      <div className={style.footerLinksCont}>
        <div className={style.opt}>
          <img
            src={Logo}
            alt="logo"
            // style={{ width: "2rem", height: "2rem" }}
            className="cursor-pointer"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />{" "}
          <span className={style.footerLinksText}>Student Life. Balanced.</span>
        </div>
        <div className={style.outlinks}>
          {" "}
          {/* <div
            className={style.footerLinksText}
            // onClick={() => navigate("/legal")}
            onClick={() => {
              window.location.reload();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Legal
          </div> */}
          {/* <div
						className={style.footerLinksText}
						onClick={() => navigate('/privacy-policy')}>
						Privacy Policy
					</div>
					<div
						className={style.footerLinksText}
						onClick={() => navigate('/terms-and-conditions')}>
						Terms & Condition
					</div> */}
        </div>

        <div
          style={{
            fontWight: 500,
            fontFamily: "Montserrat",
            marginBottom: "20px",
          }}
        >
          Estu is not a bank. Estu is a financial technology company. Banking
          services provided by{" "}
          <span style={{ fontWeight: 700 }}>
            <a
              target="_blank"
              href="https://www.mbanq.com/"
              style={{
                textDecoration: "none",
                color: "#2CCCD3",
                marginRight: ".5rem",
              }}
            >
              Mbanq’s
            </a>
          </span>
          Bank partners.
        </div>
      </div>

      <div
        className="email_sent email_sent1"
        style={emailFlag ? { visibility: "visible" } : { visibility: "hidden" }}
      >
        {emailSent}
      </div>
      <div className={style.sticky}>
        <div className={style.powered}>
          Powered by <span style={{ fontWeight: "bold" }}>Estu</span>
        </div>
        <div className={style.company}>
          <span style={{ fontWeight: "bold" }}>
            © 2024 Estu, All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
}
