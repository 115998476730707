import React, { useEffect, useState } from "react";
import style from "./nav.module.css";
import { EstuLogo, MenuIcon, RightArrow } from "../../../assets/logo/logos";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadBtn from "../downloadBtn/downloadBtn";
import Modal from "@mui/material/Modal";
import JoinWaitListPopupComponent from "../jonWaitlist/joinwaitlistPopup";

export default function NavBarComponent() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  // console.log('location', location);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      if (window.location.hash === "#waitlist") {
        handleOpen();
      }
    }
  }, [location]);

  const handleOpen = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    removeWaitlistHash();
  };

  const removeWaitlistHash = () => {
    const urlWithoutHash = window.location.href.split("#")[0];
    window.history.replaceState({}, document.title, urlWithoutHash);
  };
  const handleLogoClick = () => {
    navigate("/");
    window.location.href = "https://estulife.com";
  };

  return (
    <div className={style.container}>
      <Modal
        open={show}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JoinWaitListPopupComponent handleClose={handleClose} />
      </Modal>
      <div className={style.menus}>
        <div
          className={`d-flex flex-row gap-1 ${style.logo} hover`}
          onClick={handleLogoClick}
        >
          <EstuLogo />
        </div>
        {location.pathname == "/privacy-policy" ||
        location.pathname == "/terms-and-conditions" ? null : (
          <div
            onClick={() => setOpen(true)}
            className={`d-md-none ${style.mobileNav}`}
          >
            <MenuIcon />
          </div>
        )}
        {open ? (
          <div className={style.mobileNavbar}>
            <div>
              {" "}
              <div onClick={() => setOpen(false)} className={style.close}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </div>
              <div className={style.navContainer1}>
                {/* <div className={style.links}>Our Solution</div>
                <div className={style.links}>Pricing</div> */}
                {/* <div
									onClick={() => {
										setOpen(false);
										var access = document.getElementById('contact-us');
										access.scrollIntoView({ behavior: 'smooth' }, true);
									}}
									className={style.links}>
									Contact Us */}
                {/* </div> */}
              </div>
              {/* <div
                style={{ marginTop: 50 }}
                onClick={() => navigate("/admaster")}
                className={style.promoteButton1}
              >
                Promote your business <RightArrow />
              </div> */}
              <div style={{ marginTop: "2rem" }}>
                <DownloadBtn rightArrow={false} text="JOIN WAITLIST" />
              </div>
            </div>
          </div>
        ) : null}
        <div className={style.container_contact}>
          {/* <div className={style.link}>
            <a>Our Solution</a>
          </div>
          <div className={style.link}>
            <a>Pricing</a>
          </div> */}
          {/* {location.pathname == '/privacy-policy' ||
					location.pathname == '/terms-and-conditions' ? null : (
						<div
							className={style.link}
							onClick={() => {
								var access = document.getElementById('contact-us');
								access.scrollIntoView({ behavior: 'smooth' }, true);
							}}>
							<a>Contact Us</a>
						</div>
					)} */}
        </div>
      </div>
      {/* <div
				onClick={() => navigate('/admaster')}
				className={style.promoteButton}>
				Promote your business <RightArrow />
			</div> */}
      {/* <div className={style.show_btn}>
				<DownloadBtn rightArrow={false} text='JOIN WAITLIST' />
			</div> */}
    </div>
  );
}
