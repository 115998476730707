import React, { useState } from 'react';
import Image1 from '../../../assets/joinwaitlist.png';
import style from './joinwaitlist.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import SuccessPageWaitlist from './successPage';

export default function JoinWaitListPopupComponent({ handleClose }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		email: '',
		firstName: '',
		lastName: '',
		institute: '',
	});
	const [validation, setValidation] = useState({});
	const [step, setStep] = useState(0);

	//   .post('http://apiv2.estubank.com:8007/api/v1/mainadmin/refer-institute', data)

	const saveData = async () => {
		if (validateFields()) {
			setLoading(true);
			await axios
				.post(
					'https://40ly3b23ze.execute-api.us-east-1.amazonaws.com/prod/createReferral',
					data,
				)
				.then(
					(res) => {
						console.log(res.data);
						setStep(1);
					},
					(err) => {
						console.log(err, 'err');
						if (err && err.response.data.message === 'Email already exist') {
							setValidation({ ...validation, emailExist: true });
							setLoading(false);
						}
						setLoading(false);
					},
				);
			await setLoading(false);
		}
	};

	const validateFields = () => {
		let isValid = true;
		const newValidation = {};

		if (data.email === '' || !isValidEmail(data.email)) {
			newValidation.email = 'Please enter a valid email address';
			isValid = false;
		}

		if (data.firstName === '') {
			newValidation.firstName = 'Please enter your first name';
			isValid = false;
		}

		if (data.lastName === '') {
			newValidation.lastName = 'Please enter your last name';
			isValid = false;
		}

		if (data.institute === '') {
			newValidation.institute = 'Please enter your school name';
			isValid = false;
		}

		setValidation(newValidation);
		return isValid;
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<div className={style.outer}>
			<div className={style.container}>
				<button className={style.closeButton} onClick={handleClose}>
					<svg
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M12.25 12.25L1.75 1.75M12.25 1.75L1.75 12.25'
							stroke='#2CCCD3'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</button>
				{step === 0 ? (
					<div className={style.body}>
						<div className={style.left}>
							<div className={style.title}>
								join our waiting list and be the first to experience the
								extraordinary!
							</div>
							<div
								style={{ width: '200px !important' }}
								className='sub_ctr2__box'>
								<div className='sub_ctr2__box_label_popup sub_ctr2__box_label'>
									Email
								</div>
								<div className='sub_ctr2__box_input'>
									<input
										onChange={(e) =>
											setData({ ...data, email: e.target.value })
										}
										style={{ width: 350 }}
										type='text'
									/>
									{validation.email && (
										<p style={{ color: 'red' }} className={'validationText'}>
											{validation.email}
										</p>
									)}
								</div>
							</div>
							<div
								style={{ width: '200px !important' }}
								className='sub_ctr2__box'>
								<div className='sub_ctr2__box_label_popup sub_ctr2__box_label'>
									First Name
								</div>
								<div className='sub_ctr2__box_input'>
									<input
										onChange={(e) =>
											setData({ ...data, firstName: e.target.value })
										}
										style={{ width: 350 }}
										type='text'
									/>
									{validation.firstName && (
										<p style={{ color: 'red' }} className={'validationText'}>
											{validation.firstName}
										</p>
									)}
								</div>
							</div>
							<div
								style={{ width: '200px !important' }}
								className='sub_ctr2__box'>
								<div className='sub_ctr2__box_label_popup sub_ctr2__box_label'>
									Last Name
								</div>
								<div className='sub_ctr2__box_input'>
									<input
										onChange={(e) =>
											setData({ ...data, lastName: e.target.value })
										}
										style={{ width: 350 }}
										type='text'
									/>
									{validation.lastName && (
										<p style={{ color: 'red' }} className={'validationText'}>
											{validation.lastName}
										</p>
									)}
								</div>
							</div>
							<div
								style={{ width: '200px !important' }}
								className='sub_ctr2__box'>
								<div className='sub_ctr2__box_label_popup sub_ctr2__box_label'>
									School
								</div>
								<div className='sub_ctr2__box_input'>
									<input
										onChange={(e) =>
											setData({ ...data, institute: e.target.value })
										}
										style={{ width: 350 }}
										type='text'
									/>
									{validation.institute && (
										<p style={{ color: 'red' }} className={'validationText'}>
											{validation.institute}
										</p>
									)}
								</div>
							</div>
							{validation.emailExist && (
								<p style={{ color: 'red' }} className={'validationTexterror'}>
									Email Already Exists
								</p>
							)}
							<button
								onClick={saveData}
								disabled={loading ? true : false}
								className={`${style.joinButton} ${loading && 'bg-white'}`}>
								{loading ? <CircularProgress sx={{color:"#2cccd3"}} size={25}/> : 'Join waitlist'}
							</button>
						</div>
						<div className={style.right}>
							<img src={Image1} alt='Join Wait List popup' />
						</div>
					</div>
				) : (
					<SuccessPageWaitlist handleClose={handleClose} />
				)}
			</div>
		</div>
	);
}
