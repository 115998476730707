import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './downloadBtn.module.css';

const DownloadBtn = ({ rightArrow, text, contentSize }) => {
	const navigate = useNavigate();

	// https://apps.apple.com/us/app/estu-student-life-optimizer/id6444135259
	return (
		<>
			<a
				onClick={(_) => navigate('#waitlist')}
				style={{ textDecoration: 'none', cursor: 'pointer' }}
				className={contentSize ? styles.content_width : ''}>
				<div className={styles.download_btn_container}>
					<div className={styles.download_btn_content}>
						<div className={styles.download_btn_text}>{text}</div>
						{rightArrow && (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='25'
								viewBox='0 0 24 25'
								fill='none'>
								<path
									d='M12.5625 19.6895L19.3125 12.9395L12.5625 6.18945M18.375 12.9395H4.6875'
									stroke='#111827'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						)}
					</div>
				</div>
			</a>
		</>
	);
};

export default DownloadBtn;
