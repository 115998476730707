import React, { useEffect, useState } from 'react';
import style from './joinwaitlist.module.css';
import Confetti from 'react-confetti';
import PanaImage from '../../../assets/pana.png';

export default function SuccessPageWaitlist({ handleClose }) {
	const [showConfity, setShowContiy] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setShowContiy(false);
		}, 5000);
	}, []);

	return (
		<div className={style.success_body}>
			{showConfity && <Confetti width={850} height={500} />}
			<div className={style.success_header}>
				<p className={style.title}>🎉 Thanks for joining our waitlist! 🎉</p>
				<p className={style.des}>
				Thanks for joining our waitlist. We'll send you an email when Estu is ready to be downloaded
				</p>
			</div>
			<div className={style.success_image}>
				<img src={PanaImage} alt='Pana' />
			</div>
			<button onClick={handleClose} className={`${style.joinButton} `}>
				Done
			</button>
		</div>
	);
}
