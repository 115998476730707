import React, { useEffect, useState } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./v2/pages/Home";
import toast, { Toaster } from "react-hot-toast";
import AdMasterHome from "./pages/admaster/Admaster";
import TermsAndConditions from "./v2/pages/TermsAndConditions";
import PrivacyPolicy from "./v2/pages/PrivacyPolicy";
import LegalPolicy from "./v2/pages/LegalPolicy";
import MainRoutes from "./component/GlobalCompoents/routes";
import ElectronicConcertComponent from "./v2/pages/ElectronicConcertComponent";
import CardholderaggrementComponent from "./v2/pages/CardHolderAggrement";
import FeeSchedule from "./v2/pages/FeeSchedule";

function App() {
  const router = createBrowserRouter([{ path: "", element: <LegalPolicy /> }]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Toaster />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
